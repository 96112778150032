import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-12">
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
